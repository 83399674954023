import React from "react"
import Research from "../components/ClinicalStudies"
import SEO from "../components/seo"

const ResearchPage = () => (
  <>
    <SEO title="Clinical Research" />
    <div className="hero-section in-pages-bg">
      <div className="cover-overlay"></div>
      <div className="overlay-text">
        <h3 className="is-white font-size-42 font-weight-bold">Clinical Research</h3>
        <p className="is-white font-size-20">Check out our Clinical Research Documentation</p>
      </div>
    </div>
    <Research />
  </>
)

export default ResearchPage
